<template>
  <div>
    <div class="d-flex flex-wrap border-bottom mb-3">
      <div class="mt-4 mr-auto">
        <h4>Companies</h4>
      </div>

      <div>
        <router-link
          :to="{ name: 'newCompany' }"
          class="btn btn-success btn-with-addon text-nowrap my-3 mr-3"
        >
          <span class="btn-addon">
            <i class="btn-addon-icon fe fe fe-user-plus"></i>
          </span>
          Company
        </router-link>
      </div>
      <div>
        <div>
          <router-link
            :to="{ name: 'newCompany' }"
            class="btn btn-success text-blue btn-with-addon text-nowrap my-3 mr-3"
          >
            <span class="btn-addon">
              <i class="btn-addon-icon fe fe fe-plus"></i>
            </span>
            Branch
          </router-link>
        </div>
      </div>
      <div class="dropdown my-3 mr-3">
        <a-dropdown placement="bottomLeft" :trigger="['click']">
          <a class="btn btn-light text-blue dropdown-toggle" href="javascript: void(0);">Actions</a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <router-link
                  :to="{
                    name: 'importFile',
                    query: { entity: 'companies' },
                  }"
                >
                  Import
                </router-link>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">Export</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="d-flex flex-column justify-content-center">
        <a-tooltip title="Refresh">
          <button type="button" class="btn btn-light" @click="handleDataReload">
            <SyncOutlined :spin="reloadIconType" />
          </button>
        </a-tooltip>
      </div>
    </div>
    <div>
      <a-skeleton :loading="isCompaniesLoading" active>
        <div class="text-center my-5" v-if="!companiesExists">
          <h3>Create Companies here.</h3>
          <p class="text-muted">
            Create and manage your companies, all in one place.
          </p>
          <!-- <template> -->
          <div>
            <a-empty :description="false" />
          </div>
          <!-- </template> -->
          <div>
            <router-link :to="{ name: 'newCompany' }" class="btn btn-success my-2 text-uppercase">
              Create New Company
            </router-link>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-lg-6"></div>
          </div>
          <div>
            <CompaniesTable :companies-list="companiesList" />
          </div>
        </div>
      </a-skeleton>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'
import { ref, defineComponent, computed } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import CompaniesTable from './CompaniesTable.vue'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { CompaniesDocument } from '@/types/firebaseCollectionContracts/CompaniesDocument'

export default defineComponent({
  name: 'Companies',
  components: { SyncOutlined, CompaniesTable },
  setup() {
    const store = useStore()
    const isCompaniesLoading = computed(() => store.state.companies.companiesLoading)
    const companiesExists = computed(() => store.getters['companies/companiesExists'])
    const companiesList = computed(
      () => store.getters[`companies/companyList`] as IAppDocument<CompaniesDocument>[],
    )
    const reloadIconType = ref(false)

    const handleDataReload = async () => {
      reloadIconType.value = true
      await store.dispatch(`companies/${actions.CompaniesAction.FETCH_COMPANIES_ON_LOAD}`)
      reloadIconType.value = false
    }

    return {
      isCompaniesLoading,
      companiesExists,
      reloadIconType,
      handleDataReload,
      companiesList,
    }
  },
})
</script>
