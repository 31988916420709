
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'
import { ref, defineComponent, computed } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import CompaniesTable from './CompaniesTable.vue'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { CompaniesDocument } from '@/types/firebaseCollectionContracts/CompaniesDocument'

export default defineComponent({
  name: 'Companies',
  components: { SyncOutlined, CompaniesTable },
  setup() {
    const store = useStore()
    const isCompaniesLoading = computed(() => store.state.companies.companiesLoading)
    const companiesExists = computed(() => store.getters['companies/companiesExists'])
    const companiesList = computed(
      () => store.getters[`companies/companyList`] as IAppDocument<CompaniesDocument>[],
    )
    const reloadIconType = ref(false)

    const handleDataReload = async () => {
      reloadIconType.value = true
      await store.dispatch(`companies/${actions.CompaniesAction.FETCH_COMPANIES_ON_LOAD}`)
      reloadIconType.value = false
    }

    return {
      isCompaniesLoading,
      companiesExists,
      reloadIconType,
      handleDataReload,
      companiesList,
    }
  },
})
