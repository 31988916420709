
import { computed, defineComponent, PropType, reactive } from 'vue'
// import { CompaniesDocument } from '@/types/firebaseCollectionContracts/CompaniesDocument'
// import { useStore } from 'vuex'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { formatAddressObject } from '@/services/helpers'
import { ICompanyBranch } from '@/types/interfaces/ICompanyBranch'

const columns = [
  {
    title: 'Branch ID',
    dataIndex: 'brid',
    key: 'brid',
  },
  {
    title: 'Branch Name',
    dataIndex: 'name',
    key: 'brname',
  },
  {
    title: 'GSTIN',
    dataIndex: 'gstin',
    key: 'gstin',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Active Status',
    dataIndex: 'isActive',
    key: 'isActive',
    slots: { customRender: 'isActive' },
  },
  {
    title: 'Address',
    dataIndex: 'branchAddress',
    key: 'branchAddress',
    slots: { customRender: 'branchAddress' },
  },
]

export default defineComponent({
  name: 'BranchesTable',
  props: {
    branchesList: {
      type: Object as PropType<IAppDocument<ICompanyBranch>[] | null>,
      required: true,
    },
  },
  setup(props) {
    // const store = useStore()
    const dataSource = computed(() => props.branchesList)
    const totalTableRecords = computed(() => dataSource.value?.length)
    const showTotal = (total: number, range: Array<any>) => {
      return `${range[0]}-${range[1]} of ${total} items`
    }
    const pagination = reactive({
      position: 'bottom',
      showSizeChanger: true,
      showQuickJumper: false,
      total: totalTableRecords.value || 0,
      simple: false,
      pageSize: 10,
      showTotal: showTotal,
    })

    return {
      columns,
      pagination,
      dataSource,
      formatAddressObject,
    }
  },
})
