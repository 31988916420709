<template>
  <div class="table-responsive">
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :bordered="false"
      :row-key="(rec, idx) => idx"
      size="small"
    >
      <template #branchAddress="{text}">
        <p style="white-space: pre-wrap">
          {{ formatAddressObject(text) }}
        </p>
      </template>
      <template #isActive="{text}">
        <a-tag color="blue">
          <span class="align-middle">
            {{ text ? 'ACTIVE' : 'INACTIVE' }}
          </span>
        </a-tag>
      </template>
    </a-table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive } from 'vue'
// import { CompaniesDocument } from '@/types/firebaseCollectionContracts/CompaniesDocument'
// import { useStore } from 'vuex'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { formatAddressObject } from '@/services/helpers'
import { ICompanyBranch } from '@/types/interfaces/ICompanyBranch'

const columns = [
  {
    title: 'Branch ID',
    dataIndex: 'brid',
    key: 'brid',
  },
  {
    title: 'Branch Name',
    dataIndex: 'name',
    key: 'brname',
  },
  {
    title: 'GSTIN',
    dataIndex: 'gstin',
    key: 'gstin',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Active Status',
    dataIndex: 'isActive',
    key: 'isActive',
    slots: { customRender: 'isActive' },
  },
  {
    title: 'Address',
    dataIndex: 'branchAddress',
    key: 'branchAddress',
    slots: { customRender: 'branchAddress' },
  },
]

export default defineComponent({
  name: 'BranchesTable',
  props: {
    branchesList: {
      type: Object as PropType<IAppDocument<ICompanyBranch>[] | null>,
      required: true,
    },
  },
  setup(props) {
    // const store = useStore()
    const dataSource = computed(() => props.branchesList)
    const totalTableRecords = computed(() => dataSource.value?.length)
    const showTotal = (total: number, range: Array<any>) => {
      return `${range[0]}-${range[1]} of ${total} items`
    }
    const pagination = reactive({
      position: 'bottom',
      showSizeChanger: true,
      showQuickJumper: false,
      total: totalTableRecords.value || 0,
      simple: false,
      pageSize: 10,
      showTotal: showTotal,
    })

    return {
      columns,
      pagination,
      dataSource,
      formatAddressObject,
    }
  },
})
</script>

<style lang="scss">
.ant-pagination-item,
.ant-pagination-jump-next,
.ant-pagination-jump-prev {
  display: none !important;
}
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #f0f2f40d !important;
}
</style>
