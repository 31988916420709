<template>
  <div>
    <div class="d-flex flex-wrap justify-content-start">
      <div v-if="canShowButton(ActionButtonList.VIEW)">
        <a-tooltip placement="top" title="View">
          <a class="btn btn-sm btn-light mr-2" @click="handleClick(ActionButtonList.VIEW)">
            <i class="fe fe-eye" />
          </a>
        </a-tooltip>
      </div>
      <div v-if="canShowButton(ActionButtonList.EDIT)">
        <a-tooltip placement="top" title="Edit">
          <a class="btn btn-sm btn-light mr-2" @click="handleClick(ActionButtonList.EDIT)">
            <i class="fe fe-edit-2" />
          </a>
        </a-tooltip>
      </div>
      <div v-if="canShowButton(ActionButtonList.FAVOURITE)">
        <a-tooltip placement="top" title="Mark as favourite">
          <a class="btn btn-sm btn-light mr-2" @click="handleClick(ActionButtonList.FAVOURITE)">
            <i class="fe fe-star" />
          </a>
        </a-tooltip>
      </div>
      <div v-if="canShowButton(ActionButtonList.REFRESH)">
        <a-tooltip title="Refresh">
          <button
            type="button"
            class="btn btn-sm btn-light mr-2"
            @click="handleClick(ActionButtonList.REFRESH)"
          >
            <SyncOutlined :spin="reloadIconType" />
          </button>
        </a-tooltip>
      </div>
      <div v-if="canShowButton(ActionButtonList.DELETE)">
        <a-tooltip placement="top" title="Delete">
          <a-popconfirm title="Sure to delete?" @confirm="handleClick(ActionButtonList.DELETE)">
            <a class="btn btn-sm btn-light">
              <i class="fe fe-trash" />
            </a>
          </a-popconfirm>
        </a-tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'

export default defineComponent({
  name: 'ButtonGroupActions',
  components: {
    SyncOutlined,
  },
  props: {
    buttonList: {
      type: Object as PropType<Array<ActionButtonList>>,
      required: true,
    },
    spinRefresh: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  emits: [
    'handleViewClick',
    'handleEditClick',
    'handleFavouriteClick',
    'handleRefreshClick',
    'handleDeleteClick',
  ],
  setup(props, { emit }) {
    const reloadIconType = computed(() => props.spinRefresh)
    const canShowButton = (buttonName: ActionButtonList) => {
      if (props.buttonList.indexOf(buttonName) >= 0) {
        return true
      }
      return false
    }

    const handleClick = (buttonName: ActionButtonList) => {
      switch (buttonName) {
        case ActionButtonList.VIEW: {
          emit('handleViewClick')
          break
        }
        case ActionButtonList.EDIT: {
          emit('handleEditClick')
          break
        }
        case ActionButtonList.REFRESH: {
          emit('handleRefreshClick')
          break
        }
        case ActionButtonList.FAVOURITE: {
          emit('handleFavouriteClick')
          break
        }
        case ActionButtonList.DELETE: {
          emit('handleDeleteClick')
          break
        }
      }
    }

    return { canShowButton, ActionButtonList, handleClick, reloadIconType }
  },
})
</script>

<style scoped></style>
