
import { computed, defineComponent, PropType } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'

export default defineComponent({
  name: 'ButtonGroupActions',
  components: {
    SyncOutlined,
  },
  props: {
    buttonList: {
      type: Object as PropType<Array<ActionButtonList>>,
      required: true,
    },
    spinRefresh: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  emits: [
    'handleViewClick',
    'handleEditClick',
    'handleFavouriteClick',
    'handleRefreshClick',
    'handleDeleteClick',
  ],
  setup(props, { emit }) {
    const reloadIconType = computed(() => props.spinRefresh)
    const canShowButton = (buttonName: ActionButtonList) => {
      if (props.buttonList.indexOf(buttonName) >= 0) {
        return true
      }
      return false
    }

    const handleClick = (buttonName: ActionButtonList) => {
      switch (buttonName) {
        case ActionButtonList.VIEW: {
          emit('handleViewClick')
          break
        }
        case ActionButtonList.EDIT: {
          emit('handleEditClick')
          break
        }
        case ActionButtonList.REFRESH: {
          emit('handleRefreshClick')
          break
        }
        case ActionButtonList.FAVOURITE: {
          emit('handleFavouriteClick')
          break
        }
        case ActionButtonList.DELETE: {
          emit('handleDeleteClick')
          break
        }
      }
    }

    return { canShowButton, ActionButtonList, handleClick, reloadIconType }
  },
})
