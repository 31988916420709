
import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import { CompaniesDocument } from '@/types/firebaseCollectionContracts/CompaniesDocument'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import BranchesTable from './BranchesTable.vue'
import BankAccountsTable from './BankAccountsTable.vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'
import ButtonGroupActions from '@/components/General/ButtonGroupActions.vue'
import { useStore } from 'vuex'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import * as actions from '@/store/actions.type'

const columns = [
  {
    title: 'Company Name',
    dataIndex: 'doc.name',
    key: 'name',
  },
  {
    title: 'Primary Branch',
    dataIndex: 'doc.primaryBranch',
    key: 'primaryBranch',
  },
  {
    title: 'PAN',
    dataIndex: 'doc.pan',
    key: 'pan',
  },
  {
    title: 'Invoice Prefix',
    dataIndex: 'doc.invoicePrefix',
    key: 'invoicePrefix',
  },
  {
    title: 'Total Branches',
    key: 'totalBranches',
    slots: { customRender: 'totalBranches' },
  },
  {
    title: 'Total Bank Accounts',
    key: 'totalBankAccounts',
    slots: { customRender: 'totalBankAccounts' },
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  name: 'CompaniesTable',
  components: {
    BranchesTable,
    BankAccountsTable,
    ButtonGroupActions,
  },
  props: {
    companiesList: {
      type: Object as PropType<IAppDocument<CompaniesDocument>[] | null>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const dataSource = computed(() => props.companiesList)
    const totalTableRecords = computed(() => dataSource.value?.length)
    const actionButtonList = [ActionButtonList.EDIT, ActionButtonList.DELETE]
    const tableLoading = ref(false)

    const showTotal = (total: number, range: Array<any>) => {
      return `${range[0]}-${range[1]} of ${total} items`
    }
    const pagination = reactive({
      position: 'bottom',
      showSizeChanger: true,
      showQuickJumper: false,
      total: totalTableRecords.value || 0,
      simple: false,
      pageSize: 10,
      showTotal: showTotal,
    })

    // const handleViewClick = (rec: any) => {
    //   console.log('TODO: View - ', rec)
    // }

    const handleEditClick = (rec: any) => {
      console.log('TODO: EDIT - ', rec)
    }
    const handleDeleteClick = async (rec: IAppDocument<any>) => {
      tableLoading.value = true
      const docOperation: DocumentOperation<null> = {
        action: DocumentActions.DELETE,
        id: rec.id,
        payload: null,
        audit: null,
      }
      await store.dispatch(
        `companies/${actions.CompaniesAction.SET_OR_DELETE_COMPANY}`,
        docOperation,
      )
      tableLoading.value = false
    }
    return {
      columns,
      pagination,
      dataSource,
      // handleViewClick,
      actionButtonList,
      handleEditClick,
      handleDeleteClick,
      tableLoading,
    }
  },
})
